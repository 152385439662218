import { Store } from 'redux';

import {
  configureRTKMockStore,
  MockRtkQuery,
  getMockEndpoint,
  getMockErrorEndpoint,
  getMockLoadingEndpoint,
} from '../../testing';
import {
  assetClassesApi,
  investmentApi,
  legacyRolloverApi,
  marketplaceApi,
  offeringApi,
  restApi,
} from '../../apis';
import { offeringV5Mock } from '../offering-v5';
import { offeringProgressMock } from '../offering-progress';
import {
  bulkInvestmentEligibilityGatedMock,
  bulkInvestmentEligibilityMock,
} from '../bulk-investment-eligibility';
import { assetClasses } from '../offerings';
import { investments } from '../investments';
import { FortyActFund } from '../../enums';
import { FeeStructureEnum, FundType } from '../../types';

export const offeringStoreTestUrlHash = 'testUrlHash';

const offeringStoreDefaultEndpoints = [
  getMockEndpoint(offeringApi, 'details', { [offeringStoreTestUrlHash]: { note: offeringV5Mock } }),
  getMockEndpoint(marketplaceApi, 'getProgress', {
    [offeringStoreTestUrlHash]: offeringProgressMock,
  }),
  getMockEndpoint(assetClassesApi, 'getAssetClasses', assetClasses),
  getMockEndpoint(investmentApi, 'getAllInvestments', investments),
  getMockEndpoint(legacyRolloverApi, 'getEligibleRollover', { investments: [] }),
  getMockEndpoint(investmentApi, 'bulkInvestmentEligibility', bulkInvestmentEligibilityMock),
];

export const offeringStoreGatedEndpoints = [
  ...offeringStoreDefaultEndpoints.slice(0, -1),
  getMockEndpoint(investmentApi, 'bulkInvestmentEligibility', bulkInvestmentEligibilityGatedMock),
];

export const offeringStoreAltIncomeEndpoints = [
  getMockEndpoint(offeringApi, 'details', {
    [FortyActFund.URL_HASH]: {
      note: {
        ...offeringV5Mock,
        urlHash: FortyActFund.URL_HASH,
        title: FortyActFund.TITLE,
        fundType: FundType.FortyActFund,
      },
    },
  }),
];

export const offeringStoreTieredPricingEndpoints = [
  getMockEndpoint(offeringApi, 'details', {
    [offeringStoreTestUrlHash]: {
      note: {
        ...offeringV5Mock,
        feeStructure: FeeStructureEnum.REDUCING_BY_AMOUNT,
      },
    },
  }),
];

export const offeringStoreWaitlistEndpoints = [
  getMockEndpoint(offeringApi, 'details', {
    [offeringStoreTestUrlHash]: { note: { ...offeringV5Mock, remainingInvestment: 0 } },
  }),
  getMockEndpoint(marketplaceApi, 'getProgress', {
    [offeringStoreTestUrlHash]: { ...offeringProgressMock, remainingInvestment: 0 },
  }),
];

export const offeringStoreCapitalCallEndpoint = [
  getMockEndpoint(offeringApi, 'details', {
    [offeringStoreTestUrlHash]: { note: { ...offeringV5Mock, capitalCallEnabled: true } },
  }),
];

export const offeringStoreLoadingEndpoints = [getMockLoadingEndpoint(offeringApi, 'details')];

export const offeringStoreErrorEndpoints = [getMockErrorEndpoint(offeringApi, 'details', {})];

export const offeringStoreGetMockStore = (
  mockEndpoints: MockRtkQuery[] = offeringStoreDefaultEndpoints
) => {
  const setup = configureRTKMockStore(
    mockEndpoints,
    {
      configApi: () => ({
        httpsHost: '',
        wssHost: 'wss://127.0.0.1',
      }),
    },
    restApi.middleware
  );

  return setup.store as Store;
};
