import { Components, CssVarsTheme } from '@mui/material';

export const MuiLink: Components<CssVarsTheme>['MuiLink'] = {
  defaultProps: {
    color: 'primary',
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      cursor: 'pointer',
      color: theme.palette.Link?.[ownerState?.color || 'primary']?.color,
      backgroundImage: `linear-gradient( to right, ${
        theme.palette.Link?.[ownerState?.color || 'primary']?.color
      } 45%,transparent 45%,transparent 55%, ${
        theme.palette.Link?.[ownerState?.color || 'primary']?.color
      } 55% )`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '225% 0.1em',
      backgroundPosition: '100% 100%',
      textDecoration: 'none',
      padding: '1px',
      '&:hover': {
        backgroundPosition: '0 100%',
        transitionProperty: 'background-position',
        transitionDuration: '0.4s',
        transitionTimingFunction: 'ease-in-out',
      },
    }),
  },
};
