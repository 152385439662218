export enum PortfolioDocumentType {
  ACLI = 'ACLI',
  ACCR = 'ACCR',
  DACA = 'DACA',
  SIN = 'SIN',
  W9 = 'W9',
  WP = 'WP',
  TAXS = 'TAXS',
}

export const PortfolioManagedDocuments = [
  PortfolioDocumentType.DACA,
  PortfolioDocumentType.SIN,
  PortfolioDocumentType.WP,
];

export enum PortfolioDocumentProvider {
  YS = 'YS',
  SYNAPSE = 'SYNAPSE',
}

export const PortfolioTaxDocumentType = {
  K1: 'K-1',
  'K1-ESTMT': 'K-1 Estimate',
  K1ST: 'K-1 State',
  IRAV: 'Fair Market Valuation Report',
  '1099': '1099-INT',
  '1099-DIV': '1099-DIV',
  '1099-MISC': '1099-MISC',
  '1099-B': '1099-B',
  '1099-NEC': '1099-NEC',
};

export enum AtomicDocumentSubType {
  AccountOpening = 'account_opening',
  ExternalAccount = 'external_account',
}
