import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const SBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.radius(3),
  border: `1px solid ${theme.palette.border.default}`,
  maxWidth: 500,
  [theme.breakpoints.up('lg')]: {
    width: 300,
    '&:hover': {
      cursor: 'pointer',
      boxShadow: theme.shadows[1],
    },
  },
}));

export const ImageContent = styled('div')<{ bgImg: string }>(({ theme, bgImg }) => ({
  height: 120,
  background: `url(${bgImg}) no-repeat`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  borderTopLeftRadius: theme.shape.radius(3),
  borderTopRightRadius: theme.shape.radius(3),
  [theme.breakpoints.up('lg')]: {
    height: 160,
  },
}));

export const TextContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(6),
  rowGap: theme.spacing(3),
  textAlign: 'left',
}));
