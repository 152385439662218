import { DistributionOption, Cadence } from '../../enums';
import { PerformanceStatus } from '../..';

export const enum InvestmentEngine {
  Funds = 'FUNDS',
  Pesto = 'PESTO',
  Pflex = 'PFLEX',
}

export const enum FundProvider {
  Atomic = 'ATOMIC',
  Ssc = 'SSC',
  Theorem = 'THEOREM',
  Ys = 'YS',
  Geneva = 'GENEVA',
}

export const enum TenderStatus {
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  CLOSED = 'CLOSED',
  CREATED = 'CREATED',
  COMPLETED = 'COMPLETED',
  UNAVAILABLE = 'UNAVAILABLE',
}

export const enum InvestmentTypes {
  Investment = 'INVESTMENT',
  Liquidation = 'LIQUIDATION',
  ReinvestDistribution = 'REINVESTED_DISTRIBUTION',
  Commitment = 'COMMITMENT',
}

export const enum InvestmentStatusTypes {
  Confirmed = 'CONFIRMED',
  Matured = 'MATURED',
  Pending = 'PENDING',
}

export const enum LiquidationStatusTypes {
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Processing = 'PROCESSING',
}

export const enum ReinvestmentDistributionStatusTypes {
  Cancelled = 'CANCELLED',
  Cleared = 'CLEARED',
  Pending = 'PENDING',
}

export type InvestmentDetailStatus =
  | InvestmentStatusTypes
  | LiquidationStatusTypes
  | ReinvestmentDistributionStatusTypes;

export interface InvestmentDetailInvestment {
  amount: number;
  dateActive: string | null;
  dateInvested: string | null;
  shareCount: number | null;
  sharePrice: number | null;
  status: InvestmentDetailStatus;
  type: InvestmentTypes;
}

export interface InvestmentDetail {
  activeDate: string;
  advisor: string | null;
  annualFlatExpense: number;
  assetClass: string;
  assetClassDescription: string;
  canToggleDistributionOption: boolean | null;
  cadence?: Cadence | null;
  dateOfFirstDefault: string | null;
  distributionRate: number;
  dividendDistributionOption: DistributionOption | null;
  engine: InvestmentEngine | null;
  firstYearExpense: number;
  fundId: string | null;
  fundProvider: FundProvider | null;
  fundType: string | null;
  interestDefaultedDate: string | null;
  interestRate: number;
  investedDate: string;
  investments: InvestmentDetailInvestment[];
  irr: number;
  launchedDate: string;
  netTotalRepaid: number;
  noteUrlHash: string;
  offeringData?: {
    offeringHero?: {
      customRateString?: string;
    };
  };
  originator: string | null;
  paymentType: string;
  performanceStatus: PerformanceStatus | null;
  performanceStatusNote: string | null;
  principalDefaultedDate: string | null;
  spvType: string | null;
  subAdvisor: string | null;
  taxMonth?: string;
  taxDocument: string;
  tenderOfferId: string | null;
  tenderOfferStatus: TenderStatus;
  tenderOfferUserEligible: boolean | null;
  terminationDate: string;
  termPhrase: string;
  title: string;
  totalInvestments: number;
  totalRepaid: number;
}
