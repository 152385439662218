import { HttpMethods } from '../../enums/http';
import {
  CompletionLinkGetApiResponse,
  CompletionLinkGetApiRequest,
  LegalAgreementResponse,
  LegalAgreementRequest,
} from './atomic-gateway.model';
import { Tags, restApi } from '../common';

export const atomicGatewayApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.AtomicGatewayCompletionLink],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getCompletionLink = builder.query<
        CompletionLinkGetApiResponse,
        CompletionLinkGetApiRequest
      >({
        query: ({ investorEntityId }: CompletionLinkGetApiRequest) => {
          return {
            url: `/api/atomic-gateway/completion-link/investorEntity/${investorEntityId}`,
            method: HttpMethods.Get,
          };
        },
        providesTags: [Tags.AtomicGatewayCompletionLink],
        transformResponse: data => data.response,
      });

      const getLegalAgreementDocs = builder.query<LegalAgreementResponse, LegalAgreementRequest>({
        query: ({ investorId, documentSubType }: LegalAgreementRequest) => {
          return {
            url: `/api/atomic-gateway/legal-agreement/${investorId}/${documentSubType}`,
            method: HttpMethods.Get,
          };
        },
        transformResponse: data => data.response,
      });

      return {
        getCompletionLink,
        getLegalAgreementDocs,
      };
    },
  });
