import React, { FunctionComponent, useContext } from 'react';
import { TrustArcConsentLink, SegmentConsentLink } from './vendors';
import { FlagsContext, useFlags } from '@yieldstreet/tool-kit';

export const ConsentLink: FunctionComponent = () => {
  const { isLoading } = useContext(FlagsContext);
  const { newConsentManager } = useFlags({
    newConsentManager: false,
  });

  if (isLoading) {
    return null;
  }

  return newConsentManager ? <TrustArcConsentLink /> : <SegmentConsentLink />;
};
