import {
  ConsentCategory,
  ConsentDecision,
  ConsentDecisionState,
  ConsentState,
  FULL_TRACKER_CONSENT,
  NoDecisionState,
  ZERO_TRACKER_CONSENT,
} from './TrustArcConsent.model';

export const getConsentState = (): ConsentState => {
  const result = getConsentDecision();

  if (result) {
    // TrustArc returns [0] when the user has not made a decision
    // irrespective of the implied consent for that location

    // The following is logic to normalize the output to a consent state
    // irrespective of whether the user has made an explicit decision or not

    // Check if the user has made an explicit consent decision
    if (isConsentState(result.consentDecision)) {
      // If so, return the decision
      return result.consentDecision;
    }

    // If no explicit decision has been made, fall back to the default behaviour as per location
    if (getConsentBehaviour() !== 'eu') {
      // If the default behaviour is not EU (i.e. implied consent), return full tracker consent
      return FULL_TRACKER_CONSENT;
    }
  }

  // If no decision has been made and the default behaviour is EU, return zero tracker consent
  return ZERO_TRACKER_CONSENT;
};

export const getConsentDecision = (): ConsentDecision | undefined => {
  return window.truste?.cma?.callApi('getGDPRConsentDecision', 'yieldstreet.com');
};

export const getConsentCountry = (): string | undefined => {
  return window.truste?.eu?.bindMap?.country;
};

export const getConsentBehaviour = (): string | undefined => {
  return window.truste?.eu?.bindMap?.behaviorManager;
};

export const isConsentState = (
  consentDecision: ConsentDecisionState
): consentDecision is ConsentState => !(consentDecision as NoDecisionState).includes(0);

export const isConsentCategoryAllowed = (
  consentCategory: ConsentCategory,
  consentState = getConsentState()
) => consentState && consentState.includes(consentCategory);

export const launchConsentPreferences = () => {
  window.truste?.eu?.clickListener?.();
};

export const addTrustArcConsentListener = (onConsent: (consentState?: ConsentState) => void) => {
  const onConsentHandler = () => onConsent(getConsentState());
  document.body.addEventListener('privacyApiListener', onConsentHandler);
  document.body.addEventListener('truste-consent-gtm', onConsentHandler);
};
