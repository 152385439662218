import React, { useState, useCallback, useMemo } from 'react';

import { HeaderItem } from '../HeaderItem';
import { LoginArea } from '../LoginArea/LoginArea';
import { mainNav, userNav } from '../navigationConfig';
import { Logo } from '../Logo';
import { Hamburger } from '../Hamburger';
import { Submenu } from '../../submenu';
import {
  useNavItemProps,
  getNavItemSubMenu,
  findActiveNavItem,
  NavigationInputProps,
  NavigationLink,
} from '../../common';
import {
  Wrapper,
  Container,
  Flex,
  HamburgerWrap,
  LogoWrap,
  MenuContainer,
  Menu,
  MenuItemsContainer,
  SignButtons,
} from '../styles';
import { MobileOnly, DesktopOnly } from '../../../utils';
import { Paragraph } from '../../../content/text/Paragraph';

import Google from '../../../assets/logos/google_store_inverse.svg';
import Apple from '../../../assets/logos/apple_store_inverse.svg';
import { LoginButton, MobileMenuFooter } from './HeaderView.style';

export interface HeaderViewProps extends NavigationInputProps {
  hideLogin?: boolean;
  hideSignup?: boolean;
  notificationGroup?: Array<{ menu: string; subMenu: string; value: number }>;
  preContent?: React.ReactNode;
  midContent?: React.ReactNode;
  postContent?: React.ReactNode;
}

export const Header: React.FunctionComponent<HeaderViewProps> = props => {
  const {
    audiences,
    tier,
    userState,
    currentPath,
    notificationGroup,
    preContent,
    midContent,
    postContent,
  } = props;

  const navProps = useNavItemProps(props);

  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const toggleMenuOpen = useCallback(() => {
    setSideMenuOpen(!sideMenuOpen);
  }, [sideMenuOpen]);

  const activeItem = useMemo(() => {
    return findActiveNavItem([...mainNav, ...userNav], navProps, currentPath);
  }, [navProps, currentPath]);

  const activeSubMenu = useMemo(() => {
    return activeItem && getNavItemSubMenu(activeItem, navProps);
  }, [activeItem, navProps]);

  const activeSubItem = useMemo(() => {
    return activeSubMenu && findActiveNavItem(activeSubMenu, navProps, currentPath);
  }, [activeSubMenu, navProps, currentPath]);

  return (
    <Wrapper data-cy="header-view">
      {preContent}
      <Container>
        <Flex>
          <HamburgerWrap>
            <Hamburger opened={sideMenuOpen} onChange={toggleMenuOpen} inverse />
          </HamburgerWrap>
          <LogoWrap onChange={toggleMenuOpen}>
            <NavigationLink
              {...props}
              navItem={{
                key: 'home',
                id: 'yieldstreet-logo',
                route: userState && userState.loggedIn ? '/offerings' : '/',
                staticRoute: true,
                ariaLabel: 'Yieldstreet logo',
              }}
              raw
            >
              <Logo inverse />
            </NavigationLink>
          </LogoWrap>
        </Flex>
        <MenuContainer sideMenuOpen={sideMenuOpen} onClick={toggleMenuOpen}>
          <Menu sideMenuOpen={sideMenuOpen}>
            {userState && !userState.loggedIn && (
              <SignButtons>
                <NavigationLink
                  {...props}
                  navItem={{
                    id: 'header-login',
                    key: 'login',
                    route: '/login',
                  }}
                  raw
                >
                  <LoginButton color="primary" fullWidth data-cy="header-view-button">
                    Login
                  </LoginButton>
                </NavigationLink>
              </SignButtons>
            )}
            <DesktopOnly>
              <MenuItemsContainer>
                {mainNav.map(item => (
                  <HeaderItem
                    key={item.key}
                    activeItem={activeItem}
                    activeSubItem={activeSubItem}
                    menuItem={item}
                    currentPath={currentPath}
                    notificationGroup={notificationGroup}
                    {...props}
                  />
                ))}
              </MenuItemsContainer>
            </DesktopOnly>
            <MobileOnly>
              <MenuItemsContainer>
                {mainNav.map(item => (
                  <HeaderItem
                    key={item.key}
                    activeItem={activeItem}
                    activeSubItem={activeSubItem}
                    menuItem={item}
                    currentPath={currentPath}
                    notificationGroup={notificationGroup}
                    isSidebarItem
                    isMobile
                    {...props}
                  />
                ))}
              </MenuItemsContainer>
              <MobileMenuFooter>
                <Paragraph inverse>Download the Yieldstreet mobile app</Paragraph>
                <a
                  rel="noreferrer noopener"
                  href="https://apps.apple.com/us/app/yieldstreet-alt-investments/id1437754463"
                  target="_blank"
                >
                  <img src={Apple} />
                </a>
                <a
                  rel="noreferrer noopener"
                  href="https://play.google.com/store/apps/details?id=com.yieldstreet"
                  target="_blank"
                >
                  <img src={Google} />
                </a>
              </MobileMenuFooter>
            </MobileOnly>
          </Menu>
        </MenuContainer>
        <LoginArea
          activeItem={activeItem}
          activeSubItem={activeSubItem}
          audiences={audiences}
          tier={tier}
          {...props}
        />
      </Container>
      {midContent}
      {activeItem && activeSubMenu && !activeItem.itemsDropdown && (
        <Submenu
          activeKey={activeSubItem?.key}
          menu={activeSubMenu}
          {...props}
          preContent={null}
          postContent={null}
        />
      )}
      {postContent}
    </Wrapper>
  );
};
