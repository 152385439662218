import { gql } from 'graphql-request';

import { graphqlApi, Tags } from '../common';
import {
  InvestmentRolloverAmountRequestGraphQl,
  InvestmentRolloverAmountResponseGraphQl,
  InvestmentsByStatusRequestGraphQl,
  InvestmentsByStatusResponseGraphQl,
} from './investments.model';

export const portfolioGraphQLApi = graphqlApi
  .enhanceEndpoints({
    addTagTypes: [Tags.PortfolioInvestmentsByStatus, Tags.PortfolioInvestmentRolloverAmount],
  })
  .injectEndpoints({
    endpoints: builder => ({
      getPortfolioInvestments: builder.query<
        InvestmentsByStatusResponseGraphQl,
        InvestmentsByStatusRequestGraphQl
      >({
        providesTags: [Tags.PortfolioInvestmentsByStatus],
        query: ({
          actionableItemsOnly,
          asOfDate,
          investorId,
          managementStrategy,
          status,
          userId,
        }) => ({
          document: gql`
            query getPortfolioInvestments(
              $actionableItemsOnly: Boolean
              $asOfDate: String
              $investorId: String
              $managementStrategy: String
              $status: [String]
              $userId: String!
            ) {
              investmentByStatus(
                actionableItemsOnly: $actionableItemsOnly
                asOfDate: $asOfDate
                investorAccountId: $investorId
                managementStrategy: $managementStrategy
                portfolioId: $userId
                status: $status
              ) {
                ACTIVE {
                  gates
                  investments {
                    allowRollover
                    currentValue
                    fundType
                    investmentAmount
                    investorAccountId
                    noteTitle
                    noteUrlHash
                    performanceStatus
                    requiresUserAction
                    rolloversScheduled
                    termCurrent
                    termPercentage
                    termPhrase
                    termRemaining
                  }
                  totals {
                    currentValue
                    investmentAmount
                  }
                }
                PENDING {
                  gates
                  investments {
                    allowRollover
                    currentValue
                    fundType
                    investmentAmount
                    investorAccountId
                    noteTitle
                    noteUrlHash
                    performanceStatus
                    portfolioAvailable
                    requiresUserAction
                    rolloversScheduled
                    termCurrent
                    termPercentage
                    termPhrase
                    termRemaining
                  }
                  totals {
                    currentValue
                    investmentAmount
                  }
                }
                MATURED {
                  investments {
                    allowRollover
                    fundType
                    investmentAmount
                    investorAccountId
                    irr
                    netTotalRepaid
                    noteTitle
                    noteUrlHash
                    rolloversScheduled
                    termCurrent
                    termPercentage
                    termPhrase
                    termRemaining
                  }
                  totals {
                    investmentAmount
                    irr
                    netTotalRepaid
                  }
                }
                COMMITMENT_PENDING {
                  commitments {
                    amount
                    fundExpensesFeeType
                    fundProvider
                    fundType
                    investorAccountId
                    launchedDate
                    noteTitle
                    noteUrlHash
                    performanceStatus
                    termCurrent
                    terminationDate
                    termPercentage
                    termPhrase
                    termRemaining
                    waitlisted
                  }
                  totals {
                    amount
                  }
                }
                COMMITMENT_ACTIVE {
                  commitments {
                    amount
                    fundExpensesFeeType
                    fundProvider
                    fundType
                    investorAccountId
                    launchedDate
                    noteTitle
                    noteUrlHash
                    performanceStatus
                    termCurrent
                    terminationDate
                    termPercentage
                    termPhrase
                    termRemaining
                    waitlisted
                  }
                  totals {
                    amount
                  }
                }
              }
              lastCommentary(userId: $userId) {
                offerings
              }
            }
          `,
          variables: {
            actionableItemsOnly,
            asOfDate,
            investorId,
            managementStrategy,
            status,
            userId,
          },
        }),
      }),

      getRolloverAmount: builder.query<
        InvestmentRolloverAmountResponseGraphQl,
        InvestmentRolloverAmountRequestGraphQl
      >({
        providesTags: [Tags.PortfolioInvestmentRolloverAmount],
        query: ({ userId, investorId, noteUrlHash }) => ({
          document: gql`
            query getRolloverAmount($investorId: String!, $userId: String!, $noteUrlHash: String!) {
              maxRolloverAmount(
                portfolioId: $userId
                investorAccountId: $investorId
                noteUrlHash: $noteUrlHash
              ) {
                userId
                maturityDate
                rolloverAmountPerInvestment
                totals {
                  investmentIds
                  maxRolloverAmount
                }
              }
            }
          `,

          variables: {
            userId,
            investorId,
            noteUrlHash,
          },
        }),
      }),

      getDocuments: builder.query<any, any>({
        query: ({ fundId, investorAccountId }) => ({
          document: gql`
            query getPcap($investorAccountId: String!, $fundId: String!) {
              pcap(investorAccountId: $investorAccountId, fundId: $fundId) {
                available
              }
            }
          `,
          variables: {
            investorAccountId,
            fundId,
          },
        }),
      }),
    }),
  });
