import { ReactNode } from 'react';
import { genTestIds } from '@yieldstreet/tool-kit';

const { getTestIds } = genTestIds([
  'close-button',
  'closed-content',
  'open-button',
  'open-content',
]);

export const testIds = getTestIds('newest-modal');

export interface SubscribeModalProps {
  modalId: string;
  props?: any;
  onClose?: () => void;
  onSuccess?: () => void;
}
export interface Modal {
  open: (props?: unknown) => void;
  close: () => void;
  isOpen: () => boolean;
}

export interface ModalContextInterface {
  currentModal: string | null;
  isModalOpen: (modalId: string) => boolean;
  subscribeModal: (props: SubscribeModalProps) => Modal;
}

export interface ModalConfig {
  component: ReactNode;
  // can extend further
}

export interface ModalProviderConfig {
  [key: string]: ModalConfig;
}

export interface RegisteredModals {
  [key: string]: {
    onClose: () => void;
    onSuccess: () => void;
    props: unknown;
  };
}

export interface NewModalProviderProps {
  children: ReactNode;
  config: ModalProviderConfig;
  hostNode?: Element;
}

export const INITIAL_CONTEXT_STATE = {
  currentModal: '',
  isModalOpen: () => false,
  subscribeModal: () => ({} as Modal),
};
