import { restApi, Tags } from '../../common';

import { HttpMethods } from '../../../enums';
import {
  PortfolioPaymentActivityRequest,
  PortfolioPaymentActivityResponse,
  PortfolioTransactionsPaymentsRequest,
  PortfolioTransactionsPaymentsResponse,
  PortfolioPaymentsYearsApiResponse,
  PortfolioPaymentsYearsRequest,
  PortfolioPaymentsYearsResponse,
  PortfolioDepositApiRequest,
  PortfolioDepositApiResponse,
} from './payments.model';

export const paymentsApi = restApi
  .enhanceEndpoints({
    addTagTypes: [
      Tags.PortfolioPayments,
      Tags.PortfolioPaymentsYears,
      Tags.PortfolioTransactionPayments,
    ],
  })
  .injectEndpoints({
    endpoints: build => {
      const getPaymentActivity = build.query<
        PortfolioPaymentActivityResponse,
        PortfolioPaymentActivityRequest
      >({
        query: ({ userId, investorId, ...params }: PortfolioPaymentActivityRequest) => ({
          url: `/api/portfolio/${userId}/payment-activity`,
          method: HttpMethods.Get,
          params: {
            ...(!!investorId ? { investorAccountId: investorId } : {}),
            ...params,
          },
        }),
        providesTags: [Tags.PortfolioPayments],
      });

      const getPayments = build.query<
        PortfolioTransactionsPaymentsResponse,
        PortfolioTransactionsPaymentsRequest
      >({
        query: ({ investorId, userId, ...params }: PortfolioTransactionsPaymentsRequest) => ({
          url: `/a/api/portfolio/payments/${userId}`,
          method: HttpMethods.Get,
          params: {
            investorAccountId: investorId,
            ...params,
          },
        }),
        providesTags: [Tags.PortfolioTransactionPayments],
      });

      const getPaymentsYears = build.query<
        PortfolioPaymentsYearsResponse,
        PortfolioPaymentsYearsRequest
      >({
        query: ({
          investorId,
          loanId,
          noteUrlHash,
          page,
          pageSize,
          paymentType,
          returnsOnly,
          status,
          userId,
          managementStrategy,
        }: PortfolioPaymentsYearsRequest) => ({
          url: `/a/api/portfolio/payments/${userId}/available-years`,
          method: HttpMethods.Get,
          params: {
            investorAccountId: investorId,
            loanId,
            noteUrlHash,
            page,
            pageSize,
            paymentType,
            returnsOnly,
            status,
            managementStrategy,
          },
        }),
        providesTags: [Tags.PortfolioPaymentsYears],
        transformResponse: ({ years }: PortfolioPaymentsYearsApiResponse) => ({
          years,
        }),
      });

      const submitManagedPortfolioDeposit = build.mutation<
        PortfolioDepositApiResponse,
        PortfolioDepositApiRequest
      >({
        query: data => ({
          url: `/api/advice-engine/managed-portfolio/${data.investorEntityId}`,
          method: HttpMethods.Post,
          data,
        }),
      });

      return {
        getPaymentActivity,
        getPayments,
        getPaymentsYears,
        submitManagedPortfolioDeposit,
      };
    },
  });
