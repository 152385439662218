import React, { FunctionComponent, useEffect } from 'react';
import { withTrustArc } from '@trustarc/trustarc-segment-wrapper';
import { ConsentManagerProps } from '../../ConsentManager.model';
import { TRUSTARC_CMP_SCRIPT } from './TrustArcConsent.model';
import useScript from '@charlietango/use-script';
import { BannerGlobalStyle } from './TrustArcConsent.style';
import { GlobalStyles } from '@mui/material';

export const TrustArcConsentManager: FunctionComponent<ConsentManagerProps> = ({
  segmentWriteKey,
}) => {
  useScript(TRUSTARC_CMP_SCRIPT);

  useEffect(() => {
    window.analytics.load &&
      withTrustArc(window.analytics, { alwaysLoadSegment: true }).load(segmentWriteKey);
  }, []);

  return (
    <>
      <GlobalStyles styles={BannerGlobalStyle} />
      <div id="consent_blackbar"></div>
    </>
  );
};
