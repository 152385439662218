import React, { FunctionComponent } from 'react';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { LDContext, LDFlagSet } from 'launchdarkly-js-client-sdk';

export const LDWebFlagsProvider: FunctionComponent<{
  clientSideID: string;
  context: LDContext;
  initFlags?: LDFlagSet;
}> = ({ children, clientSideID, context, initFlags }) => {
  return React.createElement(
    LDProvider,
    {
      clientSideID,
      context,
      options: {
        bootstrap: initFlags,
        sendEventsOnlyForVariation: true,
      },
    },
    children
  );
};
