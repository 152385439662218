export enum PaymentStatus {
  Approved = 'APPR',
  Archived = 'ARCH',
  Cleared = 'CLEA',
  Fail = 'FAIL',
  Hold = 'HOLD',
  Pending = 'PEND',
  Processing = 'PROC',
  Qued = 'QUED',
  Returned = 'RETURNED',
}

export const ProcessingStatusTypes = [
  PaymentStatus.Approved,
  PaymentStatus.Fail,
  PaymentStatus.Hold,
  PaymentStatus.Pending,
  PaymentStatus.Processing,
  PaymentStatus.Qued,
  PaymentStatus.Returned,
];

export enum PaymentStatusLabel {
  APPR = 'Approved',
  CLEA = 'Settled',
  FAIL = 'Returned',
  HOLD = 'Processing',
  PEND = 'Pending',
  PROC = 'Processing',
  QUED = 'Qued',
  RETURNED = 'Returned',
}
