export const enum InvestorEntityType {
  C_CORPORATION = 'C_CORPORATION',
  ESTATE = 'ESTATE',
  INDIVIDUAL = 'INDIVIDUAL',
  IRA = 'IRA',
  LLC = 'LLC',
  PARTNERSHIP = 'PARTNERSHIP',
  S_CORPORATION = 'S_CORPORATION',
  SINGLE_MEMBER_LLC = 'SINGLE_MEMBER_LLC',
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  TRUST = 'TRUST',

  // @deprecated - use IRA instead
  LEGACY_IRA = 'LEGACY_IRA',
  // @deprecated - use IRA instead
  ROTH_IRA = 'ROTH_IRA',
  // @deprecated - use IRA instead
  TRADITIONAL_IRA = 'TRADITIONAL_IRA',
  // @deprecated - use TRUST instead
  TRUST_EIN = 'TRUST_EIN',
  // @deprecated - use TRUST instead
  TRUST_SSN = 'TRUST_SSN',
}

/**
 * Usually we export `const enums` to reduce runtime overhead by
 * removing the enum object entirely. However, in this case, we are
 * accessing members of `InvestorEntityTypeName` with dynamic values.
 *
 * We can consider exporting an object instead.
 *
 * @example
 * InvestorEntityTypeName[entity.type.name]
 * // A const enum member can only be accessed using a string literal.
 */
export enum InvestorEntityTypeName {
  C_CORPORATION = 'C Corporation',
  ESTATE = 'Estate',
  INDIVIDUAL = 'Individual',
  IRA = 'IRA',
  LLC = 'Limited Liability Company',
  PARTNERSHIP = 'Partnership',
  S_CORPORATION = 'S Corporation',
  SINGLE_MEMBER_LLC = 'Single member LLC',
  SOLE_PROPRIETOR = 'Sole Proprietor',
  TRUST = 'Trust',

  // @deprecated - use IRA instead
  LEGACY_IRA = 'IRA',
  // @deprecated - use IRA instead
  ROTH_IRA = 'Roth YSIRA',
  // @deprecated - use IRA instead
  TRADITIONAL_IRA = 'Traditional YSIRA',
  // @deprecated - use TRUST instead
  TRUST_EIN = 'Trust',
  // @deprecated - use TRUST instead
  TRUST_SSN = 'Trust',
}
