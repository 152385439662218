import { offeringApi, OfferingV5 } from '@yieldstreet/platform-kit';

const { useDetailsQuery } = offeringApi;

export const useIsUpfrontCapitalCallOffering = ({ urlHash }: { urlHash: string }) => {
  const { offering } = useDetailsQuery([urlHash], {
    selectFromResult: ({ data, isLoading, isUninitialized }) => ({
      data,
      offering: data?.[urlHash]?.note as OfferingV5,
      isLoadingOffering: isLoading || isUninitialized,
    }),
  });

  return {
    isUpfrontCapitalCall: (offering?.capitalDrawnPercentage || 0) > 0,
  };
};
