import React, { useMemo, FunctionComponent, ReactNode } from 'react';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { LDWebFlagsProvider } from '@yieldstreet/tool-kit/src/flags/vendor/launch-darkly/web';
import { getFlagsContext } from '@yieldstreet/tool-kit';
import { FlagsContextWrapper } from './FlagsContextWrapper';
import { getIsProd } from 'utils/helpers';
import { getAnalyticsIds, setAnonymousId, AnalyticsIDs } from '../analytics';
import { PageProps } from 'gatsby';

const TEST_CLIENT_ID = '627bcc00efd2f015588e5c03';
const PROD_CLIENT_ID = '627bcc00efd2f015588e5c04';

type FlagsServerData = { flags?: LDFlagSet; analyticsIds?: AnalyticsIDs };

export const FlagsProvider: FunctionComponent<
  Omit<PageProps, 'children'> & {
    serverData?: FlagsServerData;
    children: ReactNode;
  }
> = ({ serverData, children, location }) => {
  const { flags, analyticsIds } = serverData || {};

  const { clientSideID, context } = useMemo(() => {
    const isProd = getIsProd(location.hostname);

    if (analyticsIds?.anonymousId) {
      setAnonymousId(analyticsIds?.anonymousId);
    }

    const { anonymousId, userId } = analyticsIds || getAnalyticsIds();
    const clientSideID = isProd ? PROD_CLIENT_ID : TEST_CLIENT_ID;
    const context = getFlagsContext({ anonymousId, userId, host: location.hostname });

    return { clientSideID, context };
  }, []);

  return (
    <LDWebFlagsProvider clientSideID={clientSideID} context={context} initFlags={flags}>
      <FlagsContextWrapper>{children}</FlagsContextWrapper>
    </LDWebFlagsProvider>
  );
};

export const withFlagsProvider =
  <Props extends PageProps>(WrappedComponent: FunctionComponent<Props>) =>
  ({ serverData, ...props }: { serverData?: FlagsServerData } & Props) => {
    return (
      <FlagsProvider {...(props as Props)} serverData={serverData}>
        <WrappedComponent {...(props as Props)} />
      </FlagsProvider>
    );
  };
