import { styled } from '@mui/material/styles';

export const IndicatorLine = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.border.outline}`,
  height: '100%',
  position: 'absolute',
  left: 11,
  top: 2,
}));

export const IndicatorIndex = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 24,
  height: 24,
  backgroundColor: theme.palette.background.inverse,
  borderRadius: '50%',
  position: 'relative',
  zIndex: 1,
}));
