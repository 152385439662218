import { genTestIds } from '../../../testing';

const { getTestIds } = genTestIds(['total-account-balance', 'total-cash', 'total-apy']);

export const testIds = getTestIds('balance-breakdown');

export enum NumberSymbolEnum {
  CURRENCY = '$',
  PERCENTAGE = '%',
}

export const NET_ANNUALIZED_RETURN_TOOLTIP =
  'Represents an net annualized return, using an internal rate of return (IRR) methodology, with respect to the matured investments shown in your Portfolio experience, utilizing the effective dates and amounts of subscriptions and distributions to and from the investments, net of management fees and all other expenses charged to the investments. With respect to the “Portfolio Overview” view, a blended net annualized return is shown. All calculations are based on unaudited internal calculations and are subject to change. Numbers presented are rounded to the second decimal. Past performance is not a reliable indicator of future results and should not be relied upon as the basis for making an investment decision. All securities involve risk and may result in significant losses, including the loss of principal invested.';

export const NET_ANNUALIZED_RETURN_TOOLTIP_ANCHOR_LINK = '#sup2';

export const TRUNCATED_NET_ANNUALIZED_RETURN_TOOLTIP = `Represents an net annualized return, using an internal rate of return (IRR) methodology, with respect to the matured investments shown in your Portfolio experience, utilizing the effective dates and amounts of subscriptions and distributions to and from the investments, net of management fees and all other expenses charged to the investments.`;

export const PENDING_TOOLTIP =
  'Pending investment requests will show here once Yieldstreet has confirmed your investment allocation, debiting the funds from your external bank account or requesting your wire transfer.';

export enum ConfigKeysEnum {
  Active = 'active',
  BalanceBreakdown = 'balanceBreakdown',
  Default = 'default',
  Investments = 'investmentBalance',
  ReserveBalance = 'reserveBalance',
  NetAnnualizedReturn = 'irr',
  Pending = 'pending',
  Performance = 'performance',
  Total = 'total',
  WalletBalance = 'clearedBalance',
  WatchList = 'watchlist',
}

interface InvestmentBalanceBreakdownItemBase {
  key: `${ConfigKeysEnum}`;
  label: string;
  testId: string;
}

export interface InvestmentBalanceBreakdownItems extends InvestmentBalanceBreakdownItemBase {
  value?: string | number;
  prefix?: `${NumberSymbolEnum}`;
  suffix?: `${NumberSymbolEnum}`;
  indented?: boolean;
  hideWhenEmpty?: boolean;
  tooltip?: string;
  tooltipAnchor?: string;
}

interface InvestmentBalanceBreakdownSection extends InvestmentBalanceBreakdownItemBase {
  items: InvestmentBalanceBreakdownItems[];
}

export interface InvestmentBalanceBreakdown {
  [key: string]: InvestmentBalanceBreakdownSection;
}

export const INVESTMENT_BALANCE_BREAKDOWN: InvestmentBalanceBreakdown = {
  balanceBreakdown: {
    key: ConfigKeysEnum.BalanceBreakdown,
    label: 'Balance breakdown',
    testId: 'balance-breakdown-heading',
    items: [
      {
        key: ConfigKeysEnum.Investments,
        label: 'Investments',
        prefix: NumberSymbolEnum.CURRENCY,
        testId: 'investments-total',
      },
    ],
  },
  investments: {
    key: ConfigKeysEnum.Investments,
    label: 'Investments',
    testId: 'investments-heading',
    items: [
      {
        key: ConfigKeysEnum.Pending,
        label: 'Pending',
        prefix: NumberSymbolEnum.CURRENCY,
        indented: true,
        testId: 'investments-pending',
        tooltip: PENDING_TOOLTIP,
      },
      {
        key: ConfigKeysEnum.Active,
        label: 'Active',
        prefix: NumberSymbolEnum.CURRENCY,
        indented: true,
        testId: 'investments-active',
      },
      {
        key: ConfigKeysEnum.WatchList,
        label: 'Watchlist',
        prefix: NumberSymbolEnum.CURRENCY,
        indented: true,
        testId: 'investments-watchlist',
        hideWhenEmpty: true,
      },
      {
        key: ConfigKeysEnum.Default,
        label: 'Default',
        prefix: NumberSymbolEnum.CURRENCY,
        indented: true,
        testId: 'investments-default',
        hideWhenEmpty: true,
      },
    ],
  },
  performance: {
    key: ConfigKeysEnum.Performance,
    label: 'Performance',
    testId: 'performance-heading',
    items: [
      {
        key: ConfigKeysEnum.NetAnnualizedReturn,
        label: 'Net annualized return',
        suffix: NumberSymbolEnum.PERCENTAGE,
        tooltip: TRUNCATED_NET_ANNUALIZED_RETURN_TOOLTIP,
        tooltipAnchor: '#sup2',
        testId: 'performance-irr',
      },
    ],
  },
  walletBalance: {
    key: ConfigKeysEnum.WalletBalance,
    label: 'Wallet balance',
    testId: 'wallet-balance',
    items: [
      {
        key: ConfigKeysEnum.WalletBalance,
        label: 'Wallet balance',
        prefix: NumberSymbolEnum.CURRENCY,
        testId: 'wallet-balance',
      },
    ],
  },
  totalInvestments: {
    key: ConfigKeysEnum.Total,
    label: 'Total',
    testId: 'total',
    items: [
      {
        key: ConfigKeysEnum.Total,
        label: 'Total',
        prefix: NumberSymbolEnum.CURRENCY,
        testId: 'total',
      },
    ],
  },
};

export const SUMMARY_BALANCE_TOTALS: InvestmentBalanceBreakdown = {
  summaryBalance: {
    key: ConfigKeysEnum.BalanceBreakdown,
    label: 'Summary balance',
    testId: testIds.base.id,
    items: [
      {
        key: ConfigKeysEnum.Total,
        label: 'Total account balance',
        prefix: NumberSymbolEnum.CURRENCY,
        testId: testIds.totalAccountBalance.id,
      },
      {
        key: ConfigKeysEnum.WalletBalance,
        label: 'Cash',
        prefix: NumberSymbolEnum.CURRENCY,
        testId: testIds.totalCash.id,
      },
    ],
  },
};
