import { styled } from '@mui/material/styles';

import { GetSizePropsParams, GetTypePropsParams, SurfaceProps, SurfaceType } from './Surface.model';

const getSizeProps = ({ small, theme, withPadding }: GetSizePropsParams) => {
  if (!withPadding) {
    return {
      padding: 0,
    };
  }

  if (small) {
    return {
      padding: theme?.spacing(4),
    };
  }

  return {
    padding: theme?.spacing(6),
  };
};

const getTypeProps = ({ theme, type }: GetTypePropsParams) => {
  switch (type) {
    case SurfaceType.Default:
      return {
        backgroundColor: theme?.palette.background.surface,
      };

    case SurfaceType.Outline:
      return {
        backgroundColor: theme?.palette.common.white,
        borderColor: theme?.palette.border.default,
      };

    case SurfaceType.Highlight:
      return {
        backgroundColor: theme?.palette.background.surface,
        borderColor: theme?.palette.border.default,
      };

    case SurfaceType.Skeleton:
      return {
        border: `1px dashed ${theme?.palette.border.strong}`,
      };
  }
};

export const SurfaceWrapper = styled('div')<Omit<SurfaceProps, 'children'>>(
  ({ small, theme, type, withPadding, noBorder }) => ({
    border: noBorder ? '' : '1px solid transparent',
    borderRadius: theme.shape.radius(3),
    ...getSizeProps({ small, theme, withPadding }),
    ...getTypeProps({ theme, type }),
  })
);
