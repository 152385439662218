import { CommitmentStatus, PerformanceStatus } from '../../../../enums';
import { DistributionOption } from '../../../../enums/investment/distribution-option';
import { FundProvider, FundType } from '../../../../types';

export enum FundBalanceCalculationType {
  InvestedMinusCapital = 'INVESTED_MINUS_CAPITAL',
  CapitalBalance = 'CAPITAL_BALANCE',
  InvestedAmount = 'INVESTED_AMOUNT',
  NavOverride = 'NAV_OVERRIDE',
}

export enum FundCapitalBalanceReductionType {
  TotalDistributed = 'TOTAL_DISTRIBUTED',
  CapitalDistributed = 'CAPITAL_DISTRIBUTED',
  IncomeDistributed = 'INCOME_DISTRIBUTED',
}

export enum FeeBalanceProvider {
  STARTING_CAPITAL_BALANCE = 'STARTING_CAPITAL_BALANCE',
  ENDING_CAPITAL_BALANCE = 'ENDING_CAPITAL_BALANCE',
  TOTAL_INVESTED = 'TOTAL_INVESTED',
}

export interface FundConfig {
  balanceCalculationType: FundBalanceCalculationType | null;
  capitalBalanceReductionType: FundCapitalBalanceReductionType | null;
  fees?: Record<string, object>;
  feesBalanceProviders: {};
  positionsEffectiveDistributionImpact?: string | null;
  managementFeeBalanceProvider?: FeeBalanceProvider | null;
  memberExpenseBalanceProvider?: FeeBalanceProvider | null;
  managementFeeRate?: number | null;
}

export interface PortfolioSummaryFundBreakdown {
  funded: number;
  total: number;
  unfunded: number;
}

export interface PortfolioSummaryFund {
  canToggleDistributionOption: boolean;
  commitmentBreakdown: PortfolioSummaryFundBreakdown | null;
  commitmentDetails: CommitmentDetail[];
  dividendDistributionOption: DistributionOption;
  fundCode: string;
  fundConfig: FundConfig;
  fundId: string;
  fundInvestmentStatus?: string | null;
  investorAccountId: string;
  nav: number;
  navDate: string;
  noteUrlHash: string;
  sharesCount: number;
  total: number;
}

export interface CommitmentDetail {
  noteUrlHash: string;
  noteTitle: string;
  investorAccountId: string;
  status: CommitmentStatus;
  waitlisted: boolean;
  amount: number;
  launchedDate: string;
  terminationDate: string;
  commitmentDate: string;
  activationDate: string;
  termPhrase: string;
  termPercentage: number;
  termRemaining: number;
  termCurrent: number;
  distributionRate: number;
  performanceStatus: PerformanceStatus;
  performanceStatusNote: string | null;
  offeringData: object;
  fundType: FundType;
  fundProvider: FundProvider;
  fundExpensesFeeType: string;
  requiresUserAction: boolean;
}

export interface Fees {
  MANAGEMENT_FEE: ManagementFee;
}

export interface ManagementFee {
  rate: number;
}

export interface FeesBalanceProviders {
  MANAGEMENT_FEE: string;
}
