import {
  CommitmentDetail,
  InvestmentDetailInvestment,
  PerformanceStatus,
} from '@yieldstreet/platform-kit';

export interface GetStatusLabelByInvestmentTypeProps {
  investmentStatus?: InvestmentDetailInvestment['status'];
  commitmentStatus?: CommitmentDetail['status'];
  investmentType: InvestmentDetailInvestment['type'];
  performanceStatus: PerformanceStatus;
}

export const PERFORMANCE_STATUS_IN_DEFAULT = [
  PerformanceStatus.DEFAULT,
  PerformanceStatus.MARINE_DEFAULT,
  PerformanceStatus.PARTIAL_DEFAULT,
];

export const PERFORMANCE_STATUS_IN_WATCHLIST = [PerformanceStatus.MODIFIED_OUTLOOK];
