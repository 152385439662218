import {
  InvestorEntityEligibilityDetails,
  PortfolioStrategyValues,
  investorEntityApi,
  investmentApi,
  allToken,
  EntityInvestorAccount,
} from '@yieldstreet/platform-kit';
import { find, map, flattenDeep, filter, includes } from 'lodash';
import { useMemo } from 'react';

import {
  SelectInvestorAccountsProps,
  FilterInvestorAccountsProps,
  ALL_ACCOUNTS,
} from './useSelectInvestorAccounts.model';

const { useGetInvestorEntityQuery } = investorEntityApi;
const { useManagedPortfolioEligibilityQuery } = investmentApi;

export const useSelectInvestorAccounts = ({
  investorId,
  managementStrategy = PortfolioStrategyValues.Self,
}: SelectInvestorAccountsProps) => {
  const {
    data: investorEntityData,
    isFetching,
    isError: investorEntityDataIsError,
    isSuccess: investorEntityDataIsSuccess,
    refetch,
  } = useGetInvestorEntityQuery(allToken);

  const hasManagedInvestorAccounts = investorEntityData?.entities?.some(entity =>
    entity?.investorAccounts.some(
      account => account.managementStrategy === PortfolioStrategyValues.Discretionary
    )
  );

  const {
    data: managedPortfolioEligibility,
    isFetching: managedPortfolioEligibilityIsFetching,
    isError: managedPortfolioEligibilityIsError,
    isSuccess: managedPortfolioEligibilityIsSuccess,
  } = useManagedPortfolioEligibilityQuery(allToken, { skip: !hasManagedInvestorAccounts });

  const managedPortfoliosInvestorEntities = useMemo(
    () =>
      managedPortfolioEligibility?.eligibility?.investorEntities.reduce(
        (acc: string[], investorEntity: InvestorEntityEligibilityDetails) => {
          acc.push(investorEntity.investorEntityId.toString());
          return acc;
        },
        []
      ),
    [managedPortfolioEligibility]
  );

  const investorEntities = useMemo(() => {
    if (managementStrategy === PortfolioStrategyValues.Discretionary) {
      return filter(investorEntityData?.entities, entity => {
        const entityId = entity.id.toString();

        return includes(managedPortfoliosInvestorEntities, entityId);
      });
    }

    return investorEntityData?.entities;
  }, [investorEntityData, managedPortfoliosInvestorEntities, managementStrategy]);

  const filterInvestorAccounts = ({
    investorAccounts,
    managementStrategy,
  }: FilterInvestorAccountsProps) => {
    return filter(investorAccounts, { managementStrategy });
  };

  const investorAccounts = useMemo(() => {
    return flattenDeep(
      map(investorEntities, investorEntity =>
        map(
          filterInvestorAccounts({
            investorAccounts: investorEntity.investorAccounts,
            managementStrategy,
          }),
          account => ({
            id: `${account.id}`,
            name: investorEntity.name,
            investorEntityId: investorEntity.id,
          })
        )
      )
    );
  }, [investorEntities, managementStrategy]);

  const hasValidManagedInvestorEntities = useMemo(() => {
    const managed = filter(investorEntities, entity => {
      const entityId = entity.id.toString();
      return includes(managedPortfoliosInvestorEntities, entityId);
    });

    const accounts =
      managed?.map((ent: { investorAccounts: EntityInvestorAccount[] }) => ent?.investorAccounts) ??
      [];

    return accounts.some(account =>
      account.some(acct => acct?.managementStrategy === PortfolioStrategyValues.Discretionary)
    );
  }, [investorEntities, managedPortfoliosInvestorEntities]);

  const { hasMultipleInvestorAccounts, hasInvestorAccounts, hasMultipleInvestorEntities } = useMemo(
    () => ({
      hasInvestorAccounts: investorAccounts.length > 0,
      hasMultipleInvestorAccounts: investorAccounts.length > 1,
      hasMultipleInvestorEntities:
        flattenDeep(
          investorEntityData?.entities?.map(
            entity =>
              entity?.investorAccounts?.filter(
                account => account?.managementStrategy === managementStrategy
              ) || []
          )
        )?.length > 1,
    }),
    [investorAccounts.length, investorEntityData?.entities, managementStrategy]
  );

  const investorAccountList = useMemo(
    () => [
      ...(hasMultipleInvestorAccounts && managementStrategy === PortfolioStrategyValues.Self
        ? [ALL_ACCOUNTS]
        : []),
      ...investorAccounts,
    ],
    [hasMultipleInvestorAccounts, managementStrategy, investorAccounts]
  );

  const selectedInvestorAccount = useMemo(
    () => find(investorAccountList, { id: investorId }) || investorAccountList[0],
    [investorId, investorAccountList]
  );

  return {
    isSuccess:
      investorEntityDataIsSuccess &&
      ((hasManagedInvestorAccounts && managedPortfolioEligibilityIsSuccess) ||
        !hasManagedInvestorAccounts),
    isLoading: isFetching || managedPortfolioEligibilityIsFetching,
    isError: investorEntityDataIsError || managedPortfolioEligibilityIsError,
    refetch,
    investorEntities,
    hasInvestorAccounts,
    hasValidManagedInvestorEntities,
    hasMultipleInvestorAccounts,
    hasMultipleInvestorEntities,
    investorAccountList,
    selectedInvestorAccount,
  };
};
