export const TRUSTARC_CMP_SCRIPT =
  '//consent.trustarc.com/notice?domain=yieldstreet.com&c=teconsent&js=nj&noticeType=bb&gtm=1';

declare global {
  interface Window {
    analytics?: any;
    truste?: any;
  }
}

export enum ConsentCategory {
  REQUIRED = 1,
  FUNCTIONAL = 2,
  ADVERTISING = 3,
}

export enum ConsentDecisionSource {
  IMPLIED = 'implied',
  ASSERTED = 'asserted',
}

export const ZERO_TRACKER_CONSENT: ConsentState = [ConsentCategory.REQUIRED];
export const FULL_TRACKER_CONSENT: ConsentState = [
  ConsentCategory.REQUIRED,
  ConsentCategory.FUNCTIONAL,
  ConsentCategory.ADVERTISING,
];

export interface ConsentDecision {
  consentDecision: ConsentDecisionState;
  source: ConsentDecisionSource;
}

export type ConsentState = ConsentCategory[];

export type NoDecisionState = [0];
export type ConsentDecisionState = NoDecisionState | ConsentState;
