import React, { FunctionComponent } from 'react';
import { openConsentManager } from '@segment/consent-manager';
import { Link } from '@mui/material';

export const SegmentConsentLink: FunctionComponent = ({}) => {
  return (
    <span>
      <Link variant="caption2" onClick={() => openConsentManager()}>
        Website Data Collection Preferences
      </Link>
    </span>
  );
};
