import {
  InvestmentsListOrderTypes,
  PerformanceStatusTypes,
} from '../../../../useInvestmentsList.model';

import { EnhancedInvestment } from '../../../formatData';

/**
 * This is the ascending order.
 * Asc/Desc will be determined by orderKey.
 */
export const PERFORMANCE_STATUS_ORDER = [
  PerformanceStatusTypes.Processing,
  PerformanceStatusTypes.Pending,
  PerformanceStatusTypes.Active,
  PerformanceStatusTypes.Watchlist,
  PerformanceStatusTypes.Default,
];

export interface OrderByPerformanceStatusProps {
  collection: EnhancedInvestment[];
  orderKey: InvestmentsListOrderTypes;
}
