import React, { FunctionComponent } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { IndicatorIndex, IndicatorLine } from './Timeline.style';
interface TimelineItem {
  label: string;
  content: React.ReactNode;
}

interface TimelineProps {
  items: TimelineItem[];
}

export const Timeline: FunctionComponent<TimelineProps> = ({ items }) => {
  return (
    <Box>
      {items.map((item, index) => (
        <Box key={index} sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <Stack sx={{ pt: 1 }}>
              <IndicatorIndex>
                <Typography
                  variant="caption"
                  sx={theme => ({
                    color: theme.palette.text.header.inverse,
                  })}
                >
                  {index + 1}
                </Typography>
              </IndicatorIndex>
            </Stack>
            {index + 1 !== items.length ? <IndicatorLine sx={{ top: 4 }} /> : null}
          </Box>

          <Box sx={{ ml: 4 }}>{item.content}</Box>
        </Box>
      ))}
    </Box>
  );
};
