import { PortfolioStatementProviderType, PortfolioStrategyValues } from '../../../enums';

export interface UserReport {
  date: string;
  link: string;
  name: string;
  type: string;
  provider: PortfolioStatementProviderType;
  rangeType: string;
  year: string;
  rangeValue: number;
  timestamp: number;
}

export enum UserReportTypes {
  AllReports = 'ALL_REPORTS',
  MonthlyStatement = 'MONTHLY_STATEMENT',
  QuarterlyReport = 'QUARTERLY_REPORT',
  QuarterlyStatement = 'QUARTERLY_STATEMENT',
}

export interface UserReportsRequest {
  investorId?: number | string;
  managementStrategy?: PortfolioStrategyValues;
  reportType?: UserReportTypes;
  userId: number | string;
  year?: number | string;
  providers?: PortfolioStatementProviderType | PortfolioStatementProviderType[];
}

export interface UserReportsResponse {
  reports: UserReport[];
}

export interface UserReportsApiResponse extends UserReportsResponse {
  success: boolean;
}

export interface UserReportsYearsRequest {
  investorId?: number | string;
  reportType?: UserReportTypes;
  userId: number | string;
  managementStrategy?: PortfolioStrategyValues;
  providers?: PortfolioStatementProviderType | PortfolioStatementProviderType[];
}

export interface UserReportsYearsResponse {
  years: number[];
}

export interface UserReportsYearsApiResponse extends UserReportsYearsResponse {
  success: boolean;
}
