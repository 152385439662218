import cookie from 'cookie';
import { isString } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import browser from 'browser-detect';
import { hasWindow } from './helpers';
import { eventManager } from '@yieldstreet/tool-kit';

const getAnalytics = () => (window as any).analytics;

// Intialize eventManager to be able to send Segment events (used for feature flagging)
const result = browser();
eventManager.init(
  (event, props) => {
    const analytics = getAnalytics();
    analytics?.track(event, props);
  },
  {
    app: 'web',
    mobile: !!result.mobile,
  }
);

const parseSegmentCookie = (value?: string) => {
  const trimChar = '"';
  if (value && value.length) {
    let parsedValue = value;
    parsedValue = parsedValue.endsWith(trimChar)
      ? parsedValue.slice(0, -trimChar.length)
      : parsedValue;
    parsedValue = parsedValue.startsWith(trimChar)
      ? parsedValue.slice(trimChar.length)
      : parsedValue;
    return parsedValue;
  }
};

const parseUUID = (value?: string) => {
  if (value) {
    const UUIDRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
    const parseMatch = UUIDRegex.exec(value);
    return parseMatch ? parseMatch.shift() : undefined;
  }
};

export const setAnonymousId = (anonymousId: string) => {
  if (hasWindow) {
    // since analytics is being loaded by gatsby browser we did setTimeout to make sure window.analytics is avaialble
    setTimeout(() => {
      (window as any).analytics?.setAnonymousId(anonymousId);
    }, 0);
  }
};

export type AnalyticsIDs = {
  anonymousId: string;
  userId?: string;
};

export const getAnalyticsIds = (req?: Request): AnalyticsIDs => {
  let cookies;

  if (req?.headers) {
    cookies = cookie.parse(req.headers.get('cookie') || '');
  } else if (hasWindow && document.cookie) {
    cookies = cookie.parse(document.cookie);
  }

  const cookieAnonymousId = cookies?.ajs_anonymous_id;
  const cookieUserId = cookies?.ajs_user_id;

  let anonymousId = parseUUID(parseSegmentCookie(cookieAnonymousId));
  let userId = parseSegmentCookie(cookieUserId);

  if (!anonymousId || !isString(anonymousId)) {
    anonymousId = uuidv4();
    setAnonymousId(anonymousId);
  }

  return { anonymousId, userId };
};
