import { UserMetaData } from '@yieldstreet/platform-kit';
import React from 'react';

export type Flags = Record<string, any>;

export type EvalFlagParams<F extends keyof T, T extends Flags> = {
  flag: F;
  defaultValue: T[F];
};

export type EvalFlag<T extends Flags> = <F extends keyof T>(flag: F, defaultValue: T[F]) => T[F];

export type EvalFlagResult<F extends keyof T, T extends Flags> = LDEvalFlagResult<F, T>;

export type FlagsContextPayload = {
  allFlags: Flags;
  evalFlag: EvalFlag<any>;
  isLoading: boolean;
};

export type FlagsControlProps<T extends Flags> = {
  flags: T;
  evalFlag: EvalFlag<T>;
  evalAllFlags: () => void;
};

export const FlagsContext = React.createContext<FlagsContextPayload>({
  allFlags: {},
  evalFlag: () => null,
  isLoading: true,
});

export type LDFlagsUserContext = {
  key: string;
  anonymous?: boolean;
  createdAt?: string | number;
  meta?: UserMetaData;
};

export type LDFlagsFeatureContext = {
  key: string;
};

export type LDFlagsContext = {
  kind: 'multi';
  user: LDFlagsUserContext;
  feature?: LDFlagsFeatureContext;
};

export type LDEvalFlagResult<F extends keyof T, T extends Flags> = {
  flag: F;
  value: T[F];
  details?: Object | null;
};
