import React, { FunctionComponent, useContext } from 'react';
import { ConsentManagerProps } from './ConsentManager.model';
import { SegmentConsentManager } from './vendors/segment';
import { TrustArcConsentManager } from './vendors';
import { FlagsContext, useFlags } from '@yieldstreet/tool-kit';

export const ConsentManager: FunctionComponent<ConsentManagerProps> = props => {
  const { isLoading } = useContext(FlagsContext);
  const { newConsentManager } = useFlags({
    newConsentManager: false,
  });

  if (isLoading) {
    return null;
  }

  return newConsentManager ? (
    <TrustArcConsentManager {...props} />
  ) : (
    <SegmentConsentManager {...props} />
  );
};
