import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link } from '@mui/material';
import { TRUSTARC_CMP_SCRIPT } from './TrustArcConsent.model';
import useScript from '@charlietango/use-script';
import { getConsentCountry, launchConsentPreferences } from './TrustArcConsent.utils';

export const TrustArcConsentLink: FunctionComponent = ({}) => {
  const [consentAvailable, setConsentAvailable] = useState(false);
  const [consentReady] = useScript(TRUSTARC_CMP_SCRIPT);

  useEffect(() => {
    if (consentReady) {
      const consentCountry = getConsentCountry();

      if (consentCountry && consentCountry !== 'others') {
        setConsentAvailable(true);
      }
    }
  }, [consentReady]);

  return consentAvailable ? (
    <span>
      <Link variant="caption2" onClick={launchConsentPreferences}>
        Website Data Collection Preferences
      </Link>
    </span>
  ) : null;
};
