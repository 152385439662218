import { CommitmentStatus, PaymentMethodType, PerformanceStatus } from '../../enums';
import { FundProvider, FundType, gateType } from '../../types';
import { InvestmentsByStatusResponseGraphQl } from './investments.model';

const INVESTMENTS_BY_STATUS_INVESTMENTS_MOCK: InvestmentsByStatusResponseGraphQl['investmentByStatus'] =
  {
    ACTIVE: {
      investments: [
        {
          currentValue: 15000.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: FundType.ThirdPartyFund,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 15000.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'Enhanced Crypto Fund',
          noteUrlHash: 'qzqC4A',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          sharesCount: 0,
          termCurrent: 0.0,
          termPercentage: 0.0,
          termPhrase: 'N/A',
          termRemaining: 998.0,
          totalRepaid: 0,
        },
        {
          currentValue: 507.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: FundType.InternalFund,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 507.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: "Vitor's Test",
          noteUrlHash: 'lrgZSw',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          sharesCount: 0,
          termCurrent: 0.0,
          termPercentage: 8.47457627118644,
          termPhrase: '54 Mo.',
          termRemaining: 54.0,
          totalRepaid: 0,
        },
        {
          currentValue: 2000.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: FundType.ThirdPartyFund,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 2000.0,
          investorAccountId: '394817',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'FUNDS Test Offering',
          noteUrlHash: 'Z4hj1Q',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          sharesCount: 0,
          termCurrent: 0.0,
          termPercentage: 100.0,
          termPhrase: '0 Mo.',
          termRemaining: 0.0,
          totalRepaid: 0,
        },
        {
          currentValue: 14197.39,
          fexpPaid: 0,
          fundProvider: null,
          fundType: FundType.ThirdPartyFund,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 15000.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'FUNDS Test Offering',
          noteUrlHash: 'Z4hj1Q',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          sharesCount: 0,
          termCurrent: 0.0,
          termPercentage: 100.0,
          termPhrase: '0 Mo.',
          termRemaining: 0.0,
          totalRepaid: 0,
        },
        {
          currentValue: 2.02,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 2.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'Pflex Schedule Debug',
          noteUrlHash: 'Y5wcqg',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          sharesCount: 0,
          termCurrent: 12.0,
          termPercentage: 25.0,
          termPhrase: '9 Mo.',
          termRemaining: 9.0,
          totalRepaid: 0,
        },
        {
          currentValue: 1004.38,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 1000.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'Blue Origin',
          noteUrlHash: 'DPiCSg',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          rolloversScheduled: [
            {
              rolloverAmount: 1000,
              to: {
                noteUrlHash: 'abc',
                investmentId: '123',
              },
            },
          ],
          sharesCount: 0,
          termCurrent: 12.0,
          termPercentage: 33.33333333333333,
          termPhrase: '6 Mo.',
          termRemaining: 6.0,
          totalRepaid: 0,
        },
        {
          allowRollover: true,
          currentValue: 100000.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 100000.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'Bayan - Portland Multi-Family',
          noteUrlHash: 'wXGNxQ',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          sharesCount: 0,
          termCurrent: 36.0,
          termPercentage: 20.8955223880597,
          termPhrase: '53 Mo.',
          termRemaining: 53.0,
          totalRepaid: 0,
        },
        {
          currentValue: 10.33,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 10.0,
          investorAccountId: '394817',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'Pestoflex dist break',
          noteUrlHash: 'uqWmfw',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          sharesCount: 0,
          termCurrent: 60.0,
          termPercentage: 8.51063829787234,
          termPhrase: '43 Mo.',
          termRemaining: 43.0,
          totalRepaid: 0,
        },
        {
          currentValue: 1013.19,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 1000.0,
          investorAccountId: '394817',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'PFLEX Test Offering',
          noteUrlHash: 'epu_Hg',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          sharesCount: 0,
          termCurrent: 18.0,
          termPercentage: 100.0,
          termPhrase: '<1 Mo.',
          termRemaining: 1.0,
          totalRepaid: 0,
        },
        {
          currentValue: 200.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 200.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'BPDN 1',
          noteUrlHash: 'B-WQlg',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          sharesCount: 0,
          termCurrent: 30.0,
          termPercentage: 100.0,
          termPhrase: '<1 Mo.',
          termRemaining: 1.0,
          totalRepaid: 0,
        },
        {
          currentValue: 1026.7,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 1000.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'Short term notes 1',
          noteUrlHash: '--DfDw',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          sharesCount: 0,
          termCurrent: 20.0,
          termPercentage: 100.0,
          termPhrase: '<1 Mo.',
          termRemaining: 1.0,
          totalRepaid: 0,
        },
      ],
      totals: {
        currentValue: 134961.01,
        fexpPaid: 0,
        interestAccrued: 0,
        interestAccruedPostDefault: 0,
        interestAccruedPreDefault: 0,
        interestEarned: 0,
        interestPaid: 0,
        interestRate: 0,
        investmentAmount: 135719.0,
        irr: 0,
        netInterestPaid: 0,
        netTotalRepaid: 0,
        principalOutstanding: 0,
        principalOutstandingPostDefault: 0,
        principalOutstandingPreDefault: 0,
        principalRepaid: 0,
        totalRepaid: 0,
      },
      gates: {},
    },
    PENDING: {
      investments: [
        {
          currentValue: 0.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: FundType.RegAPlus,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 6000.0,
          investorAccountId: '394817',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'Growth & Income REIT',
          noteUrlHash: 'e5jyfw',
          performanceStatus: PerformanceStatus.PERFORMING,
          portfolioAvailable: false,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: true,
          sharesCount: 0,
          termCurrent: 0.0,
          termPercentage: 0.0,
          termPhrase: 'N/A',
          termRemaining: 998.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: FundType.RegAPlus,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 4000.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'Growth & Income REIT',
          noteUrlHash: 'e5jyfw',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: true,
          sharesCount: 0,
          termCurrent: 0.0,
          termPercentage: 0.0,
          termPhrase: 'N/A',
          termRemaining: 998.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: FundType.ThirdPartyFund,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 2000.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'Enhanced Crypto Fund',
          noteUrlHash: 'qzqC4A',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: true,
          sharesCount: 0,
          termCurrent: 0.0,
          termPercentage: 0.0,
          termPhrase: 'N/A',
          termRemaining: 998.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: FundType.InternalFund,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 2.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'Test Rebuild',
          noteUrlHash: 'oTXk7g',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: true,
          sharesCount: 0,
          termCurrent: 0.0,
          termPercentage: 16.666666666666664,
          termPhrase: '10 Mo.',
          termRemaining: 10.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: FundType.InternalFund,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 1000.0,
          investorAccountId: '394817',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'Test Rebuild',
          noteUrlHash: 'oTXk7g',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: true,
          sharesCount: 0,
          termCurrent: 0.0,
          termPercentage: 16.666666666666664,
          termPhrase: '10 Mo.',
          termRemaining: 10.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: FundType.InternalFund,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 10.0,
          investorAccountId: '394817',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: "Vitor's Test",
          noteUrlHash: 'lrgZSw',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: true,
          sharesCount: 0,
          termCurrent: 0.0,
          termPercentage: 8.47457627118644,
          termPhrase: '54 Mo.',
          termRemaining: 54.0,
          totalRepaid: 0,
        },
        {
          allowRollover: true,
          currentValue: 0.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: FundType.InternalFund,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 100.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'testAuto Internal Fund flare',
          noteUrlHash: 'BLN_retrospectivity_84957',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: true,
          sharesCount: 0,
          termCurrent: 0.0,
          termPercentage: -9.090909090909092,
          termPhrase: '12 Mo.',
          termRemaining: 12.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: FundType.ThirdPartyFund,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 2000.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'SFQP Fund',
          noteUrlHash: 'b2R0nw',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          sharesCount: 0,
          termCurrent: 0.0,
          termPercentage: 100.0,
          termPhrase: '5 Mo.',
          termRemaining: 5.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 1.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'YS TBG MULTIREP XI LLC CL A',
          noteUrlHash: 'HfGfrg',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: true,
          sharesCount: 0,
          termCurrent: 12.0,
          termPercentage: 33.33333333333333,
          termPhrase: '8 Mo.',
          termRemaining: 8.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 6003.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: "Eugene's Tooltip test",
          noteUrlHash: 'dX0-EA',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: true,
          sharesCount: 0,
          termCurrent: 60.0,
          termPercentage: 3.571428571428571,
          termPhrase: '81 Mo.',
          termRemaining: 81.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 110.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'YS TBG MULTIPROP I LLC CLASS A',
          noteUrlHash: 'R83YiQ',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          sharesCount: 0,
          termCurrent: 12.0,
          termPercentage: 33.33333333333333,
          termPhrase: '8 Mo.',
          termRemaining: 8.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 10.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'YS ALTNOTES I LLC',
          noteUrlHash: '5bqIvg',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          sharesCount: 0,
          termCurrent: 12.0,
          termPercentage: 33.33333333333333,
          termPhrase: '8 Mo.',
          termRemaining: 8.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 1000.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'SFQC Cool Offering',
          noteUrlHash: 'bDdjog',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          sharesCount: 0,
          termCurrent: 11.0,
          termPercentage: 100.0,
          termPhrase: '<1 Mo.',
          termRemaining: 1.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 300.0,
          investorAccountId: '394817',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'SFQP Offering test',
          noteUrlHash: 'Wz8GVw',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          sharesCount: 0,
          termCurrent: 1.0,
          termPercentage: 100.0,
          termPhrase: '<1 Mo.',
          termRemaining: 1.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 300.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'SFQP Offering test',
          noteUrlHash: 'Wz8GVw',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          sharesCount: 0,
          termCurrent: 1.0,
          termPercentage: 100.0,
          termPhrase: '<1 Mo.',
          termRemaining: 1.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 1.0,
          investorAccountId: '394817',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: "Eugene's Tooltip test",
          noteUrlHash: 'dX0-EA',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: true,
          sharesCount: 0,
          termCurrent: 60.0,
          termPercentage: 3.571428571428571,
          termPhrase: '81 Mo.',
          termRemaining: 81.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 4000.0,
          investorAccountId: '394817',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'PFLEX Test Offering',
          noteUrlHash: 'epu_Hg',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          sharesCount: 0,
          termCurrent: 18.0,
          termPercentage: 100.0,
          termPhrase: '<1 Mo.',
          termRemaining: 1.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 80000.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'Pricing Test Offering',
          noteUrlHash: 'w2nNhQ',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          sharesCount: 0,
          termCurrent: 12.0,
          termPercentage: 30.0,
          termPhrase: '7 Mo.',
          termRemaining: 7.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0.0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 112.0,
          investorAccountId: '394626',
          irr: 0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 0,
          noteTitle: 'Short term notes 1',
          noteUrlHash: '--DfDw',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          requiresUserAction: false,
          sharesCount: 0,
          termCurrent: 20.0,
          termPercentage: 100.0,
          termPhrase: '<1 Mo.',
          termRemaining: 1.0,
          totalRepaid: 0,
        },
      ],
      totals: {
        currentValue: 0.0,
        fexpPaid: 0,
        interestAccrued: 0,
        interestAccruedPostDefault: 0,
        interestAccruedPreDefault: 0,
        interestEarned: 0,
        interestPaid: 0,
        interestRate: 0,
        investmentAmount: 106949.0,
        irr: 0,
        netInterestPaid: 0,
        netTotalRepaid: 0,
        principalOutstanding: 0,
        principalOutstandingPostDefault: 0,
        principalOutstandingPreDefault: 0,
        principalRepaid: 0,
        totalRepaid: 0,
      },
      gates: {},
    },
    MATURED: {
      investments: [
        {
          currentValue: 0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 1000.0,
          investorAccountId: '394626',
          irr: -100.0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 123.88,
          noteTitle: 'SPV Deal Update',
          noteUrlHash: 'DclE4A',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          sharesCount: 0,
          termCurrent: 32.0,
          termPercentage: 100.0,
          termPhrase: '6 Mo.',
          termRemaining: 6.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: null,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 1000.0,
          investorAccountId: '394626',
          irr: -100.0,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 123.88,
          noteTitle: 'Drake Debt Deal',
          noteUrlHash: 'nhaw7y',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          sharesCount: 0,
          termCurrent: 32.0,
          termPercentage: 100.0,
          termPhrase: '6 Mo.',
          termRemaining: 6.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: FundType.InternalFund,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 200.0,
          investorAccountId: '394626',
          irr: 423.19,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 123.88,
          noteTitle: 'YS fund test',
          noteUrlHash: 'stfIIg',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          sharesCount: 0,
          termCurrent: 0.0,
          termPercentage: 100.0,
          termPhrase: '<1 Mo.',
          termRemaining: 1.0,
          totalRepaid: 0,
        },
        {
          currentValue: 0,
          fexpPaid: 0,
          fundProvider: null,
          fundType: FundType.InternalFund,
          interestAccrued: 0,
          interestAccruedPostDefault: 0,
          interestAccruedPreDefault: 0,
          interestEarned: 0,
          interestPaid: 0,
          interestRate: 0,
          investmentAmount: 200.0,
          investorAccountId: '394626',
          irr: 423.19,
          launchedDate: '',
          legalEntityType: '',
          netAssetValue: 0,
          netInterestPaid: 0,
          netTotalRepaid: 123.88,
          noteTitle: 'Dake Fund Deal',
          noteUrlHash: 'ksjdh2',
          performanceStatus: PerformanceStatus.PERFORMING,
          principalOutstanding: 0,
          principalOutstandingPostDefault: 0,
          principalOutstandingPreDefault: 0,
          principalRepaid: 0,
          sharesCount: 0,
          termCurrent: 0.0,
          termPercentage: 100.0,
          termPhrase: '<1 Mo.',
          termRemaining: 1.0,
          totalRepaid: 0,
        },
      ],
      totals: {
        currentValue: 0,
        fexpPaid: 0,
        interestAccrued: 0,
        interestAccruedPostDefault: 0,
        interestAccruedPreDefault: 0,
        interestEarned: 0,
        interestPaid: 0,
        interestRate: 0,
        investmentAmount: 1200.0,
        irr: 0.0,
        netInterestPaid: 0,
        netTotalRepaid: 800.0,
        principalOutstanding: 0,
        principalOutstandingPostDefault: 0,
        principalOutstandingPreDefault: 0,
        principalRepaid: 0,
        totalRepaid: 0,
      },
      gates: {},
    },
    COMMITMENT_PENDING: {
      commitments: [
        {
          amount: 1000,
          distributionRate: 0,
          fundExpensesFeeType: '',
          fundProvider: FundProvider.Atomic,
          fundType: FundType.FortyActFund,
          investorAccountId: '394626',
          launchedDate: '',
          noteTitle: 'Dake Fund Deal',
          noteUrlHash: 'ksjdh2',
          offeringData: {},
          performanceStatus: PerformanceStatus.PERFORMING,
          performanceStatusNote: null,
          requiresUserAction: false,
          status: CommitmentStatus.Pending,
          termCurrent: 0,
          terminationDate: '',
          termPercentage: 0,
          termPhrase: '',
          termRemaining: 0,
          waitlisted: false,
        },
      ],
      totals: {
        amount: 1000,
      },
      gates: {},
    },
    COMMITMENT_ACTIVE: {
      commitments: [],
      totals: {
        amount: 0,
      },
      gates: {},
    },
  };

export const INVESTMENTS_BY_STATUS_MOCK: InvestmentsByStatusResponseGraphQl = {
  investmentByStatus: {
    ACTIVE: {
      ...INVESTMENTS_BY_STATUS_INVESTMENTS_MOCK.ACTIVE,
      gates: {
        ...INVESTMENTS_BY_STATUS_INVESTMENTS_MOCK.ACTIVE.gates,
      },
    },
    PENDING: {
      ...INVESTMENTS_BY_STATUS_INVESTMENTS_MOCK.PENDING,
      gates: {
        ...INVESTMENTS_BY_STATUS_INVESTMENTS_MOCK.PENDING.gates,
        '394817': {
          oTXk7g: {
            attributes: {
              paymentMethod: PaymentMethodType.WIRE,
            },
            adminUserId: '',
            clearReason: 'NONE',
            date: '2023-04-14',
            externalInvestmentId: 154727,
            id: '317f6c10-da95-11ed-86ff-7fb3e1ed4a4b',
            irServiceInvestmentId: '2ba06e70-da95-11ed-86ff-7fb3e1ed4a4b',
            owner: 'USER',
            priority: 30,
            status: 'ACTIVE',
            timestamp: 1681456983,
            type: gateType.allocationPaymentPending,
            visibility: 'ALL',
          },
        },
        '394626': {
          'dX0-EA': {
            attributes: {
              paymentMethod: PaymentMethodType.WIRE,
            },
            adminUserId: '',
            clearReason: 'NONE',
            date: '2023-05-03',
            externalInvestmentId: 154805,
            id: '23511400-e9b2-11ed-87f2-69a1b46d632a',
            irServiceInvestmentId: 'd99e2350-3148-11ee-9d57-8144b4c76421',
            owner: 'USER',
            priority: 30,
            status: 'ACTIVE',
            timestamp: 1683118682,
            type: gateType.allocationPaymentPending,
            visibility: 'ALL',
          },
        },
      },
    },
    MATURED: INVESTMENTS_BY_STATUS_INVESTMENTS_MOCK.MATURED,
    COMMITMENT_PENDING: {
      commitments: [],
      totals: {
        amount: 0,
      },
      gates: {},
    },
    COMMITMENT_ACTIVE: {
      commitments: [],
      totals: {
        amount: 0,
      },
      gates: {},
    },
  },
  lastCommentary: {
    offerings: {
      Z4hj1Q: {
        id: '689ce9d0-902f-11ed-a7c0-656ecf8e2dcd',
        read: true,
        sortDate: '2023-01-09',
      },
      Y5wcqg: {
        id: '6ff47301-d3a1-11ed-8ac5-6f88c6cae517',
        read: true,
        sortDate: '2023-04-05',
      },
    },
  },
};

export const INVESTMENTS_BY_STATUS_EMPTY_MOCK = {
  investmentByStatus: {
    ...INVESTMENTS_BY_STATUS_MOCK.investmentByStatus,
    ACTIVE: {
      ...INVESTMENTS_BY_STATUS_MOCK.investmentByStatus.ACTIVE,
      investments: [],
      totals: {
        currentValue: 0,
        investmentAmount: 0,
      },
    },
    PENDING: {
      ...INVESTMENTS_BY_STATUS_MOCK.investmentByStatus.PENDING,
      investments: [],
      totals: {
        currentValue: 0,
        investmentAmount: 0,
      },
    },
  },
  lastCommentary: INVESTMENTS_BY_STATUS_MOCK.lastCommentary,
};

export const MAX_ROLLOVER_AMOUNT_MOCK = {
  maxRolloverAmount: {
    userId: '493207',
    maturityDate: '2024-03-03',
    rolloverAmountPerInvestment: [
      {
        investmentId: 'b6f0a22b-3e14-4aa5-9cd4-e7db93eb3621',
        rolloverAmount: 25000,
      },
      {
        investmentId: '7c8b5ed9-6f1a-4c9c-a6ef-42a207b91c12',
        rolloverAmount: 30000,
      },
      {
        investmentId: 'd2f3cfe6-91c9-4a42-9c47-55159e44a6db',
        rolloverAmount: 20000,
      },
      {
        investmentId: 'e4a91ed5-0c2b-49a2-a799-9ce7d14c3b7a',
        rolloverAmount: 10000,
      },
      {
        investmentId: 'f83a9c6e-2df9-4d31-9e57-efaecf6db64f',
        rolloverAmount: 5000,
      },
    ],
    totals: {
      investmentIds: [
        'b6f0a22b-3e14-4aa5-9cd4-e7db93eb3621',
        '7c8b5ed9-6f1a-4c9c-a6ef-42a207b91c12',
        'd2f3cfe6-91c9-4a42-9c47-55159e44a6db',
        'e4a91ed5-0c2b-49a2-a799-9ce7d14c3b7a',
        'f83a9c6e-2df9-4d31-9e57-efaecf6db64f',
      ],
      maxRolloverAmount: 90000,
    },
  },
};
