import { useRef, useEffect, useCallback } from 'react';
import { useFlags as useAllFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { datadogRum } from '@datadog/browser-rum';
import {
  EvalFlag,
  EvalFlagParams,
  FlagsContextPayload,
  LDEvalFlagResult,
} from '../../../flags.model';
import { BusinessEvent, eventManager } from '../../../../events';

export const useLDWebFlagsContext = (): FlagsContextPayload => {
  const ldClient = useLDClient();
  const allFlags = useAllFlags();

  const evalPending = useRef<Record<string, EvalFlagParams<any, any>>>({});

  const evalFlag: EvalFlag<any> = useCallback(
    (flag, defaultValue) => {
      // check if LD client is available
      if (ldClient) {
        // call LD variation evaluation
        const { value, reason } = ldClient.variationDetail(flag as string, defaultValue);
        const evalResult: LDEvalFlagResult<any, any> = {
          flag,
          value,
          details: reason,
        };

        // call tracking with result
        eventManager.track(BusinessEvent.USER_FLAG_EVALUATED, evalResult);

        datadogRum.addFeatureFlagEvaluation(flag as string, value);

        return value;
      } else {
        const evalParams: EvalFlagParams<any, any> = {
          flag: flag as string,
          defaultValue,
        };
        // if LD client not available
        // push params to call later
        evalPending.current[flag as string] = evalParams;
      }
    },
    [ldClient]
  );

  useEffect(() => {
    // if any pending calls
    const evalCalls = Object.values(evalPending.current);
    if (ldClient && evalCalls.length) {
      // evaluate all pending calls
      evalCalls.forEach(({ flag, defaultValue }) => evalFlag(flag, defaultValue));
      // reset pending calls
      evalPending.current = {};
    }
  }, [ldClient, evalFlag]);

  const isLoading = !ldClient;

  return { allFlags, evalFlag, isLoading };
};
