import { PortfolioStrategyValues } from '../../../enums';
import { Payment, PortfolioPayments } from '../../../types';

export interface PortfolioPaymentActivityRequest {
  end?: string;
  investorId?: string;
  noteUrlHash?: string;
  start?: string;
  userId: number;
  managementStrategy?: PortfolioStrategyValues;
}

export interface PortfolioPaymentActivityResponse extends PortfolioPayments {
  investments: PortfolioPaymentInvestment[];
}

export interface PortfolioPaymentInvestment {
  amount: number;
  assetClass: string;
  launchDate: string;
  noteUrlHash: string;
  status: string;
  title: string;
  investmentType: string;
}

export interface PortfolioTransactionsPaymentsRequest {
  investorId?: string | number;
  loanId?: string;
  noteUrlHash?: string;
  page?: number;
  pageSize?: number;
  paymentType?: PortfolioPaymentTypes;
  status?: PortfolioPaymentStatuses | PortfolioPaymentStatuses[];
  userId: number;
  year?: number;
  returnsOnly?: boolean;
  managementStrategy?: PortfolioStrategyValues;
}

export interface PortfolioTransactionsPaymentsResponse {
  currentPage: number;
  items: Payment[];
  totalCount: number;
  totalPages: number;
}

export interface PortfolioPaymentsYearsRequest {
  investorId?: string;
  loanId?: string;
  noteUrlHash?: string;
  page?: number;
  pageSize?: number;
  paymentType?: string;
  returnsOnly?: boolean;
  status?: string;
  userId: number;
  managementStrategy?: PortfolioStrategyValues;
}

export interface PortfolioPaymentsYearsResponse {
  years: number[];
}

export interface PortfolioPaymentsYearsApiResponse extends PortfolioPaymentsYearsResponse {
  success: boolean;
}

export enum PortfolioPaymentStatuses {
  CLEARED = 'CLEARED',
  HOLD = 'HOLD',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  RETURNED = 'RETURNED',
  FAILED = 'FAILED',
}

export const portfolioPaymentStatusLabel = {
  [PortfolioPaymentStatuses.CLEARED]: 'Settled',
  [PortfolioPaymentStatuses.PROCESSING]: 'Processing',
  [PortfolioPaymentStatuses.FAILED]: 'Returned',
};
export interface PortfolioDepositApiRequest {
  investorEntityId: string;
  source: {
    investorAccountId: number;
    bankAccountId: string;
  };
  amount: number;
  automateClearing?: boolean;
}

export interface PortfolioDepositApiResponse {
  success: boolean;
  response: {
    id: number;
  };
}

export enum PortfolioPaymentTypes {
  Deposit = 'DEPOSIT',
  Equalization = 'EQUALIZATION',
  FundExpenses = 'FUND_EXPENSES',
  Incentive = 'INCENTIVE',
  Investment = 'INVESTMENT',
  Payment = 'PAYMENT',
  Refund = 'REFUND',
  WalletInterest = 'WALLET_INTEREST',
  Withdrawal = 'WITHDRAWAL',
  CustodialFee = 'CUSTODIAL_FEE',
  ReserveBalanceTransfer = 'RESERVE_BALANCE_TRANSFER',
  LiquidationDiscount = 'LIQ_DISCOUNT',
  Settlement = 'SETTLEMENT',
}

export const portfolioPaymentLabels = {
  [PortfolioPaymentTypes.Deposit]: 'Deposit',
  [PortfolioPaymentTypes.Equalization]: 'Equalization',
  [PortfolioPaymentTypes.FundExpenses]: 'Fund Expenses',
  [PortfolioPaymentTypes.Incentive]: 'Incentive',
  [PortfolioPaymentTypes.Investment]: 'Investment',
  [PortfolioPaymentTypes.Payment]: 'Payment',
  [PortfolioPaymentTypes.Refund]: 'Refund',
  [PortfolioPaymentTypes.WalletInterest]: 'Interest',
  [PortfolioPaymentTypes.CustodialFee]: 'Custodial Fee',
  [PortfolioPaymentTypes.ReserveBalanceTransfer]: 'Reserve Balance Transfer',
  [PortfolioPaymentTypes.LiquidationDiscount]: 'Liquidation Discount',
};
