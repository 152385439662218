import React from 'react';
import { Link } from 'react-head';

const Head = () => (
  <>
    <Link rel="preconnect" href="https://www.googletagmanager.com" />
    <Link rel="preconnect" href="https://www.google-analytics.com" />
    <Link rel="preconnect" href="https://api.segment.io" />
    <Link rel="preconnect" href="https://cdn2.yieldstreet.com" />
    <Link rel="preconnect" crossOrigin="anonymous" href="//heapanalytics.com" />
    <Link rel="preconnect" crossOrigin="anonymous" href="//cdn.heapanalytics.com" />

    <Link
      rel="preload"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      href="https://cdn2.yieldstreet.com/public_images/fonts/linik-sans/en-subset/LinikSans-Regular.woff2"
    />
    <Link
      rel="preload"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      href="https://cdn2.yieldstreet.com/public_images/fonts/linik-sans/en-subset/LinikSans-Light.woff2"
    />
    <Link
      rel="preload"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      href="https://cdn2.yieldstreet.com/public_images/fonts/linik-sans/en-subset/LinikSans-SemiBold.woff2"
    />
    <Link
      rel="preload"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      href="https://cdn2.yieldstreet.com/public_images/fonts/linik-sans/en-subset/LinikSans-Medium.woff2"
    />
    <Link
      rel="preload"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      href="https://cdn2.yieldstreet.com/public_images/fonts/linik-sans/en-subset/LinikSans-Bold.woff2"
    />
    <Link
      rel="preload"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      href="https://cdn2.yieldstreet.com/public_images/fonts/linik-sans/en-subset/LinikSans-Italic.woff2"
    />
    <Link
      rel="preload"
      as="style"
      href="https://cdn2.yieldstreet.com/public_images/fonts/linik-sans/en-subset/stylesheet.css"
      onLoad={e => (e.currentTarget.rel = 'stylesheet')}
    />
    <noscript>
      <Link
        rel="stylesheet"
        href="https://cdn2.yieldstreet.com/public_images/fonts/linik-sans/en-subset/stylesheet.css"
      />
    </noscript>
  </>
);

export default Head;
